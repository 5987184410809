#projects_container {
    padding: 100px;
}

#projects_title {
    font-size: 4vw;
}

#projects_list_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;
    /*background-color: blueviolet;*/
}