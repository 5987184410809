body {
    background-color: rgb(10, 14, 28);
}

#cubes_images {
    margin: auto;
    margin-top: 30px;
    width: 100%;
    max-width: 1300px;
    display: flex;
    z-index:0;
}

#main_title {
    position: absolute;
    left: 50%;
    translate: -50%;
    text-align: center;
    font-size: 11vw;
    color: rgb(255, 255, 255);
    margin-top: 11%;
    text-shadow: 2px 2px 4px #0000003d;
}

#by_stevenmj {
    font-weight: 200;
    position: absolute;
    left: 50%;
    translate: -50%;
    text-align: center;
    font-size: 4vw;
    color: rgb(255, 255, 255);
    margin-top: 25%;
}

#subtitle {
    font-weight: 200;
    width: 100%;
    position: absolute;
    left: 50%;
    translate: -50%;
    text-align: center;
    font-size: 2vw;
    color: rgb(214, 214, 214);
    margin-top: 31%;
}